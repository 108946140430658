<div class="text-xs md:text-base sticky top-0 shadow-md z-20 mx-auto">
  <nav
    class="rounded p-2 bg-white w-full h-16 flex justify-between items-center"
  >
    <CompanySelect v-if="!routeWithoutCompanySelected" />

    <search-input
      v-show="routeName == 'companyManagement'"
      class="w-1/2 max-w-md relative left-7 md:left-0"
      size="medium"
      v-model="companySearch.keySearch"
      :isIcon="true"
      placeholder="Nhập mã số thuế hoặc tên doanh nghiệp..."
      @inputSearch="companySearch.keySearch = $event"
      @doneTyping="searchInput()"
    />

    <p
      v-if="routeName == 'editInvoices' && getDetailInvoice && getDetailInvoice.company"
      class="pl-8 md:pl-3"
    >
      {{getDetailInvoice.company.tenDoanhNghiep}}
    </p>

    <p v-else-if="!getSelectedCompany" class="pl-8 md:pl-3">
      Bạn chưa có công ty
    </p>
    <span>&nbsp;</span>

    <div class="mx-1 text-right flex items-center gap-2">
      <div class="flex">
        <!-- <span class="text-xsss italic">Dữ liệu cập nhật lúc<br>{{ cacheDate }}</span> -->
        <el-button size="mini" icon="el-icon-refresh" @click="invalidateCache" />
      </div>

      <div
        class="flex items-center cursor-pointer text-sm"
        @click="toggleUserDropdown = !toggleUserDropdown"
      >
        <div class="mr-2">
          <p class="font-bold text-gray-600 cut-text">
            {{getAuthUser.emailQuanLyTaiKhoan}}
          </p>

          <div
            class="text-gray-400 flex justify-between items-center"
            v-if="!routeWithoutCompanySelected"
          >
            <div
              v-if="getAuthUser.id == getSelectedCompany.owner.id"
              class="
                bg-gradient-to-r
                p-1
                rounded
                from-gray-200
                via-gray-200
                to-gray-100
              "
              :class="[
                {'from-indigo-500': getAuthUser.plan == 'premium'},
                {'via-purple-500': getAuthUser.plan == 'premium'},
                {'to-pink-500': getAuthUser.plan == 'premium'},
                {'text-white': getAuthUser.plan == 'premium'}
              ]"
            >
              <span class="capitalize font-semibold"
                >{{ getAuthUser.plan }}</span
              >
            </div>

            <span v-if="getAuthUser.roles && getAuthUser.roles.length">&nbsp;</span> 
            <span
              class="font-semibold"
              v-if="getAuthUser.id == getSelectedCompany.owner.id"
            >
              Owner
            </span>

            <span
              class="font-semibold truncate role-name-title"
              v-if="getAuthUser.roles && getAuthUser.roles.length
                && getAuthUser.id != getSelectedCompany.owner.id
              "
            >
              {{ getAuthUser.roles[0]['role__name'] }}
            </span>
          </div>
        </div>

        <UserIcon :user="user" />
      </div>

      <button
        v-show="toggleUserDropdown"
        @click="toggleUserDropdown = false"
        tabindex="-1"
        class="fixed inset-0 h-full w-full cursor-default"
      ></button>

      <div class="inline-block text-right">
        <div
          class="absolute right-0 top-14 w-60 rounded-sm dark-shadow bg-white"
        >
          <div v-show="toggleUserDropdown">
            <router-link
              :to="{name: 'profile'}"
              @click="toggleUserDropdown = false"
            >
              <div
                class="
                  text-gray-700
                  hover-nav
                  flex
                  items-center
                  px-4
                  py-2
                  text-sm
                  justify-start
                  cursor-pointer
                  font-bold
                "
              >
                <i class="el-icon-s-custom mr-2" />
                Thông tin
              </div>
            </router-link>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/201223_iTAX_-_Bao_gia_DV_quan_ly_hoa_don_dien_tu.pdf"
              target="_blank"
            >
              <i class="el-icon-money mr-2" />
              Báo giá
            </a>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/Thoa_thuan_dich_vu.pdf"
              target="_blank"
            >
              <i class="el-icon-notebook-2 mr-2" />
              Thỏa thuận dịch vụ
            </a>
            <a
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              href="https://tokhaithue.vn/documents/Chinh_sach_quyen_rieng_tu.pdf"
              target="_blank"
            >
              <i class="el-icon-lock mr-2" />
              Chính sách quyền riêng tư
            </a>

            <div
              class="
                text-gray-700
                hover-nav
                flex
                items-center
                px-4
                py-2
                text-sm
                justify-start
                cursor-pointer
                font-bold
              "
              @click="signout"
            >
              <i class="el-icon-d-arrow-left mr-2" />
              Đăng xuất
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
