<div>
  <div class="text-black md:hidden z-50 fixed top-7 left-4">
    <button
      @click="changeSidebar(hideSidebar)"
      class="mobile-menu-button focus:bg-white"
    >
      <img src="@/assets/icons/icon_bar.svg" alt="" class="w-5 h-5" />
    </button>
  </div>
  <div
    class="
      shadow-right
      md:h-screen
      bg-white
      md:z-10 md:sticky md:top-0
      overflow-y-scroll
    "
  >
    <!-- Desktop -->
    <div
      v-show="hideSidebar"
      class="
        md:mt-0
        bg-white
        md:bg-none
        sidebar
        text-black
        w-56
        h-full
        py-2
        px-2
        inset-y-0
        left-0
        transform
        z-10
        md:relative md:translate-x-0
        transition
        duration-200
        ease-in-out
        fixed
        big-sidebar
      "
    >
      <div class="text-primary hidden md:block cursor-pointer space-x-2 px-2">
        <div
          class="flex justify-between items-center"
          @click="changeSidebar(hideSidebar)"
        >
          <div class="text-center">
            <img src="@/assets/logo_only.png" alt="" class="w-36" />
          </div>
          <div class="ml-4">
            <img
              src="@/assets/icons/icon_bar.svg"
              alt=""
              class="w-5 h-5 fill-bar"
            />
          </div>
        </div>
      </div>

      <nav class="mt-12 md:mt-2">
        <router-link :to="{name: 'companyManagement'}" class="custom-navbar" v-if="tabStatus['companyManagement']">
          <i class="el-icon-office-building" />
          <span class="ml-3">Quản lý công ty</span>
        </router-link>

        <router-link :to="{name: 'users'}" class="custom-navbar" v-if="tabStatus['users']">
          <i class="el-icon-user" />
          <span class="ml-3">Người dùng</span>
        </router-link>

        <router-link :to="{name: 'dashBoard'}" class="custom-navbar" v-if="tabStatus['dashBoard']">
          <i class="el-icon-data-line" />
          <span class="ml-3">Thống kê</span>
        </router-link>

        <router-link :to="{name: 'invoices'}" class="custom-navbar" v-if="tabStatus['invoices']">
          <i class="el-icon-document" />
          <span class="ml-3">Hóa đơn</span>
        </router-link>

        <router-link :to="{name: 'reports'}" class="custom-navbar" v-if="tabStatus['reports']">
          <i class="el-icon-document-checked" />
          <span class="ml-3">Báo cáo</span>
        </router-link>

        <router-link :to="{name: 'invoiceLog'}" class="custom-navbar" v-if="tabStatus['invoiceLog']">
          <i class="el-icon-refresh-left" />
          <span class="ml-3">Lịch sử</span>
        </router-link>

        <router-link :to="{name: 'mailBox'}" class="custom-navbar" v-if="tabStatus['mailBox']">
          <i class="el-icon-takeaway-box" />
          <span class="ml-3">Hòm thư</span>
        </router-link>

        <router-link :to="{name: 'invoice_summary'}" class="custom-navbar" v-if="tabStatus['invoice_summary']">
          <i class="el-icon-data-analysis" />
          <span class="ml-3">Tổng hợp dữ liệu</span>
        </router-link>

        <router-link :to="{name: 'connect-gov'}" class="custom-navbar" v-if="tabStatus['connect-gov']">
          <i class="el-icon-school" />
          <span class="ml-3">Kết nối cơ quan thuế</span>
        </router-link>

        <!-- ITAX ADMIN only -->
        <div v-if="getAuthUser.is_itax_admin">
          <router-link :to="{name: 'system-statistic'}" class="custom-navbar">
            <i class="w-4 h-4 el-icon-setting"></i>
            <span class="ml-3">Thống kê hệ thống</span>
          </router-link>

          <el-tooltip class="item" effect="dark" placement="right">
            <template #content> <div v-html="user_emails"></div> </template>
            <div
              class="
                py-2.5
                pl-3
                pr-3
                custom-navbar
                justify-between
                users-online
              "
            >
              <span class="flex items-center gap-2">
                <i class="el-icon-user"></i>
                <p class="text-xs">User Online:</p>
                <b> {{ total_online }} </b>
              </span>
            </div>
          </el-tooltip>

          <div :class="taskCount <= 0 ? 'opacity-20': ''">
            <a
              :href="taskManagementUrl"
              class="py-2.5 pl-4 pr-0 custom-navbar pending-task"
              target="_blank"
            >
              <span class="flex items-center gap-2">
                <img
                  src="@/assets/icons/task.svg"
                  class="inline-block w-4 h-4 fill-color"
                />
                <p class="text-xs">Jobs Queue:</p>
                <b> {{ taskCount }} </b>
                <i class="el-icon-loading" v-if="taskCount > 0"></i>
              </span>
            </a>
          </div>
        </div>
      </nav>
    </div>

    <!-- Mobile -->
    <div
      v-show="!hideSidebar"
      class="
        hidden
        md:block
        sidebar
        text-primary
        w-14
        h-full
        py-2
        px-2
        absolute
        inset-y-0
        left-0
        transform
        -translate-x-full
        md:relative md:translate-x-0
        transition
        duration-200
        ease-in-out
      "
    >
      <a
        href="#"
        class="flex items-center justify-center h-7 space-x-2"
        @click="changeSidebar(hideSidebar)"
      >
        <img src="@/assets/icons/icon_bar.svg" alt="" class="w-4 h-4" />
      </a>
      <nav class="mt-2">
        <el-tooltip
          class="item"
          effect="dark"
          content="Quản lý công ty"
          placement="right"
          v-if="tabStatus['companyManagement']"
        >
          <router-link :to="{name: 'companyManagement'}" class="custom-navbar mobile">
            <i class="el-icon-office-building" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Người dùng"
          placement="right"
          v-if="tabStatus['users']"
        >
          <router-link :to="{name: 'users'}" class="custom-navbar mobile">
            <i class="el-icon-user" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Thống kê"
          placement="right"
          v-if="tabStatus['dashBoard']"
        >
          <router-link :to="{name: 'dashBoard'}" class="custom-navbar mobile">
            <i class="el-icon-data-line" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Hóa đơn"
          placement="right"
          v-if="tabStatus['invoices']"
        >
          <router-link :to="{name: 'invoices'}" class="custom-navbar mobile">
            <i class="el-icon-document" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Báo cáo"
          placement="right"
          v-if="tabStatus['reports']"
        >
          <router-link :to="{name: 'reports'}" class="custom-navbar mobile">
            <i class="el-icon-document-checked" />
          </router-link>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="Lịch sử"
          placement="right"
          v-if="tabStatus['invoiceLog']"
        >
          <router-link :to="{name: 'invoiceLog'}" class="custom-navbar mobile">
            <i class="el-icon-refresh-left" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Hòm thư"
          placement="right"
          v-if="tabStatus['mailBox']"
        >
          <router-link :to="{name: 'mailBox'}" class="custom-navbar mobile">
            <i class="el-icon-takeaway-box" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Tổng hợp dữ liệu"
          placement="right"
          v-if="tabStatus['invoice_summary']"
        >
          <router-link :to="{name: 'invoice_summary'}" class="custom-navbar mobile">
            <i class="el-icon-data-analysis" />
          </router-link>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Kết nối cơ quan thuế"
          placement="right"
          v-if="tabStatus['connect-gov']"
        >
          <router-link :to="{name: 'connect-gov'}" class="custom-navbar mobile">
            <i class="el-icon-school" />
          </router-link>
        </el-tooltip>

        <div v-if="getAuthUser.is_itax_admin" >
          <el-tooltip
            class="item text-black"
            effect="dark"
            content="Thống kê hệ thống"
            placement="right"
          >
            <router-link :to="{name: 'system-statistic'}" class="custom-navbar mobile">
              <i class="w-4 h-4 el-icon-setting"></i>
            </router-link>
          </el-tooltip>

          <el-tooltip
            class="item users-online"
            effect="dark"
            content="User Online"
            placement="right"
          >
            <div class="custom-navbar mobile w-9/12">
              {{ total_online }}
            </div>
          </el-tooltip>

          <el-tooltip
            v-if="getAuthUser.is_itax_admin"
            class="item pending-task"
            effect="dark"
            content="Jobs Queue"
            placement="right"
          >
            <div class="custom-navbar mobile w-9/12">
              {{ taskCount }}
            </div>
          </el-tooltip>
        </div>

      </nav>
    </div>
  </div>
</div>
