<template>
  <VueMultiselect
    ref="companySelect"
    class="block left-7 md:left-0"
    v-model="selectCompany"
    @search-change="triggerSearch"
    :searchable="searchable"
    placeholder="Nhập tên hoặc MST để tìm kiếm công ty..."
    label="text"
    :options="companySelectOptions"
    :internal-search="false"
    :loading="searchCompanyLoading"
    :close-on-select="false"
    showNoOptions="true"
    @select="handleSelectCompany($event)"
    @scroll.passive="handleScroll"
    deselectLabel=""
    selectLabel=""
    autocomplete="'new-password'"
  >
    <template #noResult> Không có kết quả </template>
    <template #NoOptions> Không có kết quả </template>
  </VueMultiselect>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import { COMPANY_LIMIT } from "@/consts";
import { URL } from "@/api/company";
import { mapGetters } from "vuex";

export default {
  components: {
    VueMultiselect,
  },
  data() {
    return {
      companySelectOptions: [],
      searchCompanyLoading: false,
      selectCompany: null,
      isLastPage: true,
      companySearch: {
        keySearch: null,
        page: 1,
      },
    };
  },
  created() {
    // Fetch data
    if (this.getCompanies.length) {
      if (this.getSelectedCompany && !this.getSelectedCompany.id) {
        this.changeSelectedCompany(this.getCompanies[0]);
      }
      this.selectCompany = this.getSelectedCompany;
    }
    this.getCompaniesInfo();
  },

  computed: {
    ...mapGetters(["getCompanies", "getSelectedCompany", "getAuthUser"]),
    routeNeedToAppendOptions() {
      return (
        ["listInvoices", "invoiceLog", "users"].indexOf(this.$route.name) != -1
      );
    },
  },
  methods: {
    async handleSelectCompany(company) {
      let { id } = company;
      if (id == undefined) {
        // Load more button
        this.companySearch = {
          keySearch: this.companySearch.keySearch,
          page: this.companySearch.page + 1,
        };
        await this.getCompaniesInfo();
        return;
      }

      this.$refs["companySelect"].deactivate(); // Close select manually
      this.changeSelectedCompany(company);

      // Reset query onclose
      this.companySearch = {
        keySearch: "",
        page: 1,
      };
      this.getCompaniesInfo();
    },

    changeSelectedCompany(company) {
      if (company.id == -1) {
        this.$store.dispatch("setSelectedCompany", company);
        return;
      }

      this.getDetailCompany(company.id);
    },

    async getDetailCompany(id_company) {
      const result = await this.$request({
        url: URL.EDIT_DETAIL_COMPANY.replace(":id", id_company),
      });

      const res = result.data;
      if (res.code == 200) {
        this.$store.dispatch("setSelectedCompany", res.data);
        this.selectCompany = res.data;
      }
    },

    async getCompaniesInfo() {
      this.searchCompanyLoading = true;
      const params = {
        key_search: this.companySearch.keySearch,
        page: this.companySearch.page,
        minimum: true,
        limit: COMPANY_LIMIT,
      };
      const result = await this.$request({ url: URL.LIST_COMPANY, params });
      const res = result.data;
      if (res.code == 200) {
        if (res.data.length >= 1) {
          this.isLastPage = true
          let result = res.data;
          // set company if exists

          if (res.data.previous) {
            // Append for load more case
            result = [...this.getCompanies, ...result];
          }
          this.$store.dispatch("setCompanies", result);
        } else {
          this.isLastPage = true;
          this.$store.dispatch("setCompanies", []);
        }
      }
      this.searchCompanyLoading = false;
    },

    async triggerSearch(query) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(async () => {
        // Search trigger after 300ms if no more input
        this.companySearch = {
          keySearch: query,
          page: 1,
        };
        await this.getCompaniesInfo();
      }, 300);
    },

    prepareDataForSelect() {
      let companies = this.getCompanies;
      if (this.routeNeedToAppendOptions && this.getAuthUser.is_itax_admin) {
        companies = [
          { id: -1, tenDoanhNghiep: "Tất cả công ty" },
          ...this.getCompanies,
        ];
      }
      this.companySelectOptions = companies.map((company) => {
        company.text = company.tenDoanhNghiep;
        if (company.id > -1) {
          company.text += " - " + company.mst;
        }
        return company;
      });
      if (!this.isLastPage) {
        this.companySelectOptions.push({ text: "Tải thêm..." });
      }
    },
  },
  watch: {
    getCompanies() {
      this.prepareDataForSelect();
    },
    async $route() {
      this.prepareDataForSelect();
      if (
        this.getSelectedCompany &&
        !this.routeNeedToAppendOptions &&
        this.getSelectedCompany.id == -1
      ) {
        this.changeSelectedCompany(this.getCompanies[0]);
      }
    },
  },
};
</script>

<style scoped>
/* multiselect customize */
::v-deep(.multiselect__option--highlight) {
  background: #409eff !important;
  outline: none;
  color: white;
}
.multiselect {
  max-width: 30rem;
  width: calc(100% - 9.5rem);
}
::v-deep(.multiselect__single) {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  color: #606266;

  /* Restrict overflow not over 3 lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  padding: 4px;
}

@media screen and (max-width: 500px) {
  ::v-deep(.multiselect__single) {
    font-size: 0.7rem;
  }
}

::v-deep(.multiselect__tags) {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-left: 0;
  padding-right: 1rem;
  /* border-color: #c0c4cc; */
  border-width: 1px;
  border-color: #dcdfe6;
}
::v-deep(.multiselect__select) {
  padding-right: 0;
  padding-left: 0;
  margin-right: -0.75rem;
}
::v-deep(.multiselect__input) {
  margin: 0;
}
::v-deep(.multiselect__option) {
  padding: 10px;
  font-size: 0.85rem;
  line-height: 1;
  min-height: 0;
}
::v-deep(.multiselect__content-wrapper)::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::v-deep(.multiselect__content-wrapper)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1f1;
}
/* Handle */
::v-deep(.multiselect__content-wrapper)::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 2px;
}
/* Handle on hover */
::v-deep(.multiselect__content-wrapper)::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
::v-deep(.multiselect__content-wrapper) {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
::v-deep(.multiselect__element:nth-of-type(even)) {
  background: rgba(64, 158, 255, 0.1);
}

::v-deep(.select2) {
  width: 23rem !important;
}
@media screen and (max-width: 500px) {
  ::v-deep(.select2) {
    width: 9rem !important;
  }
  .cut-text {
    width: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .cut-text {
    width: 100px !important;
  }
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 15rem;
  white-space: nowrap;
}
/* multiselect customize */
</style>
