<div>
  <el-config-provider :locale="locale">
    <div class="relative min-h-screen md:flex">
      <sidebar @changeSide="hiddenSidebar = $event" />
      <div
        class="flex-1 bg-content p-2"
        :class=" hiddenSidebar ? 'activeSidebarBig' : 'activeSidebarSmall' "
      >
        <navbar />
        <div class="mt-3 content">
          <div class="mt-3 h-full">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </el-config-provider>
</div>
