<template>
  <div class="md:mx-4 mx-2 flex cursor-pointer">
    <div
      class="flex justify-end items-center"
      @click="isLanguage = !isLanguage"
    >
      <img :src="choseLanguage.flag" alt="photo" class="w-6 h-4 mr-2" />
      <span>{{ choseLanguage.country }}</span>
    </div>

    <button
      v-if="isLanguage"
      @click="isLanguage = false"
      tabindex="-1"
      class="fixed inset-0 h-full w-full cursor-default"
    ></button>
    <div class="relative inline-block text-left">
      <div
        class="
          origin-top-right
          absolute
          right-0
          top-12
          mt-2
          w-44
          rounded-md
          shadow-lg
          bg-gray-50
        "
      >
        <div class="py-1" v-if="isLanguage" role="none">
          <div
            v-for="country in listLanguage"
            :key="country.id"
            class="
              text-gray-700
              hover:bg-gray-100
              flex
              items-center
              px-4
              py-2
              text-sm
            "
            @click="changeLanguage(country)"
          >
            <img :src="country.flag" alt="photo" class="w-7 h-auto mr-2" />
            <span>{{ country.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flagVietnam from "@/assets/co_viet_nam.png";
import flagJapan from "@/assets/co_nhat.jpg";
import flagEnglish from "@/assets/co_anh.png";
export default {
  data() {
    return {
      listLanguage: [
        {
          id: 1,
          code: "vi",
          name: "VietNam",
          flag: flagVietnam,
        },
        {
          id: 2,
          code: "ja",
          name: "Japan",
          flag: flagJapan,
        },
        {
          id: 1,
          code: "en",
          name: "English",
          flag: flagEnglish,
        },
      ],
      isLanguage: false,
      choseLanguage: {
        flag: flagVietnam,
        country: "VietNam",
      },
    };
  },
  created() {
    var languge = JSON.parse(sessionStorage.getItem("languge"));
    if (languge) {
      this.choseLanguage.flag = languge.flag;
      this.choseLanguage.country = languge.name;
    }
  },
  methods: {
    changeLanguage(value) {
      this.choseLanguage.flag = value.flag;
      this.choseLanguage.country = value.name;
      this.isLanguage = false;
      this.$i18n.locale = value.code;
      sessionStorage.setItem("languge", JSON.stringify(value));
    },
  },
};
</script>
