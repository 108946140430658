export const URL = {
  LIST_COMPANY: "/companies",
  ADD_COMPANY: "/companies",
  EDIT_DETAIL_COMPANY: "/companies/:id",
  GET_DVHC: "dvhc",
  LOOKUP_DETAIL_COMPANY: "/companies/lookupinfo/:id",
  LOGOUT: "/user/logout",
  ALL_AVAILABLE_OWNER: "/users/get_all_available_owner",
  DELETE_USER: "/users/remove_company_company_super_admin_sub",
  SYSTEM_HEALTH: "system/jobqueue",
  FAILED_LOOKUP_INFO_INVOICES: '/invoices/lookup_info_failed_invoices',
};
