import { URL as COMPANY_URL } from "@/api/company";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hideSidebar: true,
      taskCount: 0,
      taskManagementUrl: "https://jobs.v2.tokhaithue.vn:30443",
      failedLookupInvoices: {
        count: 0,
        isUpdating: 0,
      },
      total_online: 0,
      user_emails: "",
      idInterval: null,
      tabStatus: {
        companyManagement: true,
        users: true,
        dashBoard: true,
        invoices: true,
        reports: true,
        invoiceLog: true,
        mailBox: true,
        invoice_summary: true,
        "connect-gov": true,
      },
    };
  },
  methods: {
    changeSidebar(value) {
      this.hideSidebar = !value;
      this.$emit("changeSide", this.hideSidebar);
    },

    setSidebarWidth() {
      this.$nextTick(() => {
        let windowSize = document.body.clientWidth;
        const HD_WIDTH = 1280;
        this.hideSidebar = windowSize > HD_WIDTH;
        this.$emit("changeSide", this.hideSidebar);
      })
    },

    async getSystemHealth() {
      const result = await this.$request({
        url: COMPANY_URL.SYSTEM_HEALTH,
        isHideLoading: true,
      });

      const res = result.data;
      if (res.code == 200) {
        this.failedLookupInvoices = res.data.failed_lookup_info_invoices;
        this.taskCount = res.data.job_queue_lenth;
        this.total_online = res.data.user.total_online;
        this.user_emails = res.data.user.latest_users_online;
      }
    },

    sendRequestRereadFailedLookupInvoices() {
      this.$request({
        url: COMPANY_URL.FAILED_LOOKUP_INFO_INVOICES,
      });
    },

    handleLookupAllInvoiceStatusFailed() {
      this.$swal({
        title: "Cập nhật trạng thái hóa đơn",
        text: "Thao tác này có thể làm giảm hiệu suất hệ thống, hãy đảm bảo thao tác được thực hiện vào khung giờ lượng truy cập thấp",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.failedLookupInvoices.isUpdating = true;
          this.sendRequestRereadFailedLookupInvoices();
        }
      });
    },

    handleReload() {
      if (
        this.getAuthUser.is_itax_admin &&
        this.taskManagementUrl.indexOf("5555") == -1
      ) {
        this.idInterval = setInterval(() => this.getSystemHealth(), 180000);
      }
    },

    sidebarPermissions() {
      if (this.getAuthUser.is_itax_admin) {
        this.tabStatus = {
          companyManagement: true,
          users: true,
          dashBoard: true,
          invoices: true,
          reports: true,
          invoiceLog: true,
          mailBox: true,
          invoice_summary: true,
          "connect-gov": true,
        };
        return;
      }

      const isShow = this.getCompanies.length > 0 || this.getSelectedCompany;
      this.tabStatus = {
        companyManagement: true,
        users: isShow,
        dashBoard: isShow,
        invoices: isShow,
        reports: isShow,
        invoiceLog: isShow,
        mailBox: isShow,
        invoice_summary: isShow,
        "connect-gov": isShow,
      };
    },
  },

  mounted() {
    this.setSidebarWidth();
    window.addEventListener("resize", this.setSidebarWidth);
    this.handleReload();
    this.getSystemHealth();
    this.sidebarPermissions();
  },

  computed: {
    ...mapGetters([
      "getWindowFocusState",
      "getSelectedCompany",
      "getCompanies",
      "getAuthUser",
    ]),
    roleUser() {
      return this.$store.state.setRoleUser.role;
    },
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    getWindowFocusState(state) {
      if (state) {
        this.handleReload(true);
      } else {
        clearInterval(this.idInterval);
      }
    },
    async $route() {
      this.setSidebarWidth();
    },
    getCompanies() {
      this.sidebarPermissions();
    },
  },
};
