import navbar from "@/views/layouts/navbar/navbar.vue";
import sidebar from "@/views/layouts/sidebar/sidebar.vue";
import { ElConfigProvider } from "element-plus";
import vi from "element-plus/lib/locale/lang/vi";
export default {
  components: {
    navbar,
    sidebar,
    ElConfigProvider,
  },
  data() {
    return {
      hiddenSidebar: true,
    };
  },
  setup() {
    return {
      locale: vi,
    };
  },
};
